import React from 'react';
import {Button, Form, Input, Pagination, Select, Space, Table, Typography, Modal, message} from 'antd';
import axios from '../../request/axios';
import moment from 'moment';
import OrderDetailsModal from '../order/OrderDetailsModal';
import DateRangePicker from '../../components/DateRangePicker';
import CustomerShopSelector from './components/CustomerShopSelector';
import ModelConstants from '../../constants/ModelConstants';
import Money from "../../components/Money";
import {LoadingOutlined} from "@ant-design/icons";
import YouyuUtils from "../../utils/YouyuUtils";

const {Option} = Select;
const {Text, Link} = Typography;

class TaobaoOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loading_total_stat: false,
      loading_export: false,
      records: [],
      product_status: 0,
      pagination: {page: 1, page_size: 15},
      total: 0
    };
  }

  componentDidMount() {
    this.loadData();
  }

  handleInputClear = (e) => {
    if (!e.target.value) this.loadData();
  }

  handlePaginationChange = (page, page_size) => {
    this.setState({
      pagination: {
        page: page,
        page_size: page_size
      }
    }, this.loadData);
  }

  loadData = () => {
    this.setState({
      loading: true
    });
    let values = this.formRef.getFieldsValue();
    let query_params = {...values, ...{date_range: [values.date_range[0].format('Y-M-D'), values.date_range[1].format('Y-M-D')]}, ...this.state.pagination};
    axios.get('customer-shop-orders/taobao-orders', {params: query_params}).then((res) => {
      const {data} = res.data;
      this.setState({
        loading: false,
        records: data.data,
        total: data.total,
        total_stat: null
      });
    });
  };

  pageStat = () => {
    const {records} = this.state;
    let order_total_price = 0;
    let total_price = 0;
    records.forEach(item => {
      order_total_price += parseFloat(item.order_total_price ?? 0);
      total_price += parseFloat(item.total_price ?? 0);
    })
    return {total_price, order_total_price};
  }

  loadTotalStat = () => {
    this.setState({loading_total_stat: true});
    let values = this.formRef.getFieldsValue();
    let query_params = {
      ...values, ...{date_range: [values.date_range[0].format('Y-M-D'), values.date_range[1].format('Y-M-D')]},
      query_type: 'stat'
    };
    axios.get('customer-shop-orders/taobao-orders', {params: query_params}).then((res) => {
      const {data} = res.data;
      this.setState({
        loading_total_stat: false,
        total_stat: data
      });
    });
  };

  handleResubmit = (record) => {
    const self = this;
    Modal.confirm({
      title: '订单“' + record.id + '”确定要重新提交充值吗？',
      ...YouyuUtils.confirmModalDefaultProps,
      onOk() {
        axios.post('shop-orders/taobao-resubmit', {id: record.id}).then((res) => {
          message.success('操作成功');
        }).finally(() => {
          self.loadData();
        });
      }
    });
  };

  resetForm = () => {
    this.formRef && this.formRef.resetFields();
    this.loadData();
  }

  export = () => {
    this.setState({loading_export: true});
    let values = this.formRef.getFieldsValue();
    let query_params = {
      ...values, ...{date_range: [values.date_range[0].format('Y-M-D'), values.date_range[1].format('Y-M-D')]},
      query_type: 'export'
    };
    axios.get('customer-shop-orders/taobao-orders', {params: query_params, responseType: 'blob'}).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const file_name = `淘宝订单_${values.date_range[0].format('Y-M-D')}_${values.date_range[1].format('Y-M-D')}.xlsx`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
    }).finally(() => {
      this.setState({
        loading_export: false
      });
    });
  }

  render() {
    const {loading_total_stat, records, total_stat} = this.state;
    const page_stat = this.pageStat();

    // 表格操作
    const operations = <div className='text-right'>
      <Pagination current={this.state.pagination.page} pageSize={this.state.pagination.page_size}
                  onChange={this.handlePaginationChange} showTotal={(total) => '共' + total + '条记录'}
                  total={this.state.total} pageSizeOptions={[15, 25, 50, 100]}/>
    </div>;

    return (
      <div>
        <div className='query-form-container'>
          <Form className='query-form' ref={ref => this.formRef = ref} onFinish={this.loadData}
                initialValues={{date_range: DateRangePicker.today()}}>
            <div className='query-form__inputs_container'>
              <Form.Item label='订单号' name='id'>
                <Input placeholder='淘宝订单号/平台订单号' style={{width: 180}} onPaste={() => setTimeout(this.loadData, 10)}
                       onChange={this.handleInputClear}/>
              </Form.Item>
              <Form.Item label='充值账号' name='charge_account'>
                <Input placeholder='充值账号' style={{width: 160}} onPaste={() => setTimeout(this.loadData, 10)}
                       onChange={this.handleInputClear}/>
              </Form.Item>
              {/*<Form.Item label='店铺' name='shop_id'>*/}
              {/*<CustomerShopSelector placeholder='选择淘宝店铺' onChange={this.loadData}*/}
              {/*shop_type={ModelConstants.CUSTOMER_SHOP_TYPE.TAOBAO}*/}
              {/*show_balance={true} style={{width: 160}}/>*/}
              {/*</Form.Item>*/}
            </div>
            <div className='query-form__inputs_container'>
              <Form.Item label='淘宝卖家' name='seller_nick'>
                <Input placeholder='淘宝卖家' style={{width: 165}} onPaste={() => setTimeout(this.loadData, 10)}
                       onChange={this.handleInputClear}/>
              </Form.Item>
              <Form.Item label='淘宝买家' name='buyer_nick'>
                <Input placeholder='淘宝买家' style={{width: 160}} onPaste={() => setTimeout(this.loadData, 10)}
                       onChange={this.handleInputClear}/>
              </Form.Item>
              <Form.Item label='订单状态' name='status'>
                <Select placeholder='选择订单状态' style={{width: 160}} onChange={this.loadData} allowClear>
                  <Option value='10'>充值中</Option>
                  <Option value='20'>交易成功</Option>
                  <Option value='30'>交易失败</Option>
                </Select>
              </Form.Item>
            </div>
            <div className='query-form__inputs_container'>
              <Form.Item label='下单日期' name='date_range'>
                <DateRangePicker onChange={this.loadData}/>
              </Form.Item>
            </div>
            <div className='query-form__operations'>
              <Space>
                <Button type='primary' htmlType='submit'>筛选</Button>
                <Button onClick={this.export} loading={this.state.loading_export}>导出</Button>
                <Link onClick={this.resetForm}>重置筛选条件</Link>
              </Space>
            </div>
          </Form>
        </div>
        <Table className='mt-4' rowKey='id' dataSource={this.state.records} loading={this.state.loading}
               pagination={false}>
          <Table.Column title='淘宝订单号' dataIndex='id' key='id' align='center'
                        render={(value) => <Text copyable>{value}</Text>}/>
          <Table.Column title='淘宝卖家' dataIndex='seller_nick' key='seller_nick' align='left' width={100}/>
          <Table.Column title='淘宝买家' dataIndex='buyer_nick' key='buyer_nick' align='left' width={100}/>
          <Table.Column title='充值账号' dataIndex='charge_account' key='charge_account' align='left'/>
          <Table.Column title='淘宝总价|平台总价|利润' dataIndex='total_price' key='total_price' align='right' width={210}
                        render={(value, record) =>
                          <>
                            <Money value={record.total_price}/> | <Money value={record.order_total_price}/> | <Money
                            value={record.total_price - record.order_total_price}/>
                          </>
                        }/>
          {/*<Table.Column title='平台总价' dataIndex='order_total_price' key='order_total_price' align='right' width={90}*/}
          {/*              render={(value, record) => <>*/}
          {/*                <Money value={value}/>*/}
          {/*              </>}/>*/}
          {/*<Table.Column title='利润' dataIndex='profit' key='profit' align='right' width={50} render={(value, record) => <>*/}
          {/*  <Money value={record.total_price - record.order_total_price}/>*/}
          {/*</>}/>*/}
          <Table.Column title='订单状态' dataIndex='status' key='status' align='left' width={100}
                        render={(value, record) =>
                          <>
                            {
                              record.status === '1' ? <span style={{color: 'grey'}}>待充值</span> :
                                record.status === '3' ? <span style={{color: 'red'}}>提交失败</span> :
                                  record.order_status === '100' ? <span style={{color: 'blue'}}>充值中</span> :
                                    record.order_status === '200' ? <span style={{color: 'green'}}>交易成功</span> :
                                      record.order_status === '300' ? <span style={{color: 'purple'}}>可疑订单</span> :
                                        record.order_status === '500' ? <span style={{color: 'red'}}>交易失败</span> :
                                          record.status === '2' ? <span style={{color: 'blue'}}>提交成功</span> :
                                            '-'
                            }
                          </>
                        }/>
          <Table.Column title='状态信息' dataIndex='status_info' key='status_info' align='left' width={120}
                        render={(value, record) =>
                          <Text>{record.order_status === '200' ? '交易成功' : record.order_status_info ?? record.status_info}</Text>}/>/>
          <Table.Column title='交易时间' dataIndex='created_at' key='created_at' align='center' width={110}
                        render={(value) => moment(value).format('Y-MM-DD HH:mm:ss')}/>
          <Table.Column title='平台订单' dataIndex='order_id' key='order_id' align='center' width={100}
                        render={(value) => value ?
                          <Link onClick={() => this.orderDetailsModal.openModal(value)}>{value}</Link> : '-'}/>
          <Table.Column title='操作' dataIndex='action' key='action' align='center' width={70}
                        render={(value, record) =>
                          <>
                            {
                              (record.status === '3' || (record.status === '2' && record.order_status === ModelConstants.ORDER_STATUS.FAIL)) ?
                                <Link
                                  onClick={this.handleResubmit.bind(this, record)}>重新提交</Link> : '--'
                            }
                          </>
                        }/>
        </Table>
        {
          records.length !== 0 && <div className='mt-4 px-4 py-2.5 flex border'>
            <div>
              <div><Text type='secondary'>本页统计数据</Text></div>
              <div className='mt-0.5'>
                <Text>淘宝总价：<Money postfix='元' value={page_stat.total_price ?? 0}/> &nbsp;
                  平台总价：<Money postfix='元' value={page_stat.order_total_price ?? 0}/> &nbsp;
                  利润合计：<Money postfix='元' value={(page_stat.total_price - page_stat.order_total_price) ?? 0}/></Text>
              </div>
            </div>
            <div className='ml-10'>
              <div><Text type='secondary'>全部统计数据</Text></div>
              <div className='mt-0.5'>
                {
                  total_stat
                    ?
                    <Text>淘宝总价：<Money postfix='元' value={total_stat.total_price ?? 0}/> &nbsp;
                      平台总价：<Money postfix='元' value={total_stat.order_total_price ?? 0}/> &nbsp;
                      利润合计：<Money postfix='元'
                                  value={(total_stat.total_price - total_stat.order_total_price) ?? 0}/></Text>
                    :
                    <>
                      {
                        loading_total_stat
                          ? <LoadingOutlined/>
                          : <Link onClick={this.loadTotalStat}>点击查看</Link>
                      }
                    </>
                }
              </div>
            </div>
          </div>
        }
        <div className='mt-4'>{operations}</div>
        <OrderDetailsModal ref={ref => this.orderDetailsModal = ref}/>
      </div>
    )
      ;
  }
}

export default TaobaoOrders;