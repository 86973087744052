import {Button, Card, Form, Input, message, Modal, Pagination, Select, Space, Table, Typography} from 'antd';
import 'zent/css/tabs.css';
import React from 'react';
import axios from '../../request/axios';
import Money from '../../components/Money';
import YouyuUtils from '../../utils/YouyuUtils';
import CustomerBalanceRecordTypeTag from '../../components/CustomerBalanceRecordTypeTag';
import moment from 'moment';
import OrderDetailsModal from '../order/OrderDetailsModal';
import DateRangePicker from '../../components/DateRangePicker';
import {LoadingOutlined} from '@ant-design/icons';

const {Option} = Select;

const {Paragraph, Text, Link} = Typography;

class CustomerBalanceRecords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loading_total_stat: false,
      loading_export: false,
      records: [],
      pagination: {page: 1, page_size: 15, total: 0},
      total_stat: null
    };
  }

  componentDidMount() {
    this.loadData();
  }

  handleInputClear = (e) => {
    if (!e.target.value) this.loadData();
  }

  handlePaginationChange = (page, page_size) => {
    this.setState({
      pagination: {
        page: page,
        page_size: page_size,
        total: this.state.pagination.total
      }
    }, this.loadData.bind(this, false));
  }

  loadData = (resetPagination = true) => {
    let {pagination} = this.state;
    if (resetPagination) {
      pagination.page = 1;
    }
    this.setState({loading: true});
    let values = this.formRef.getFieldsValue();
    let query_params = {...values, ...{date_range: [values.date_range[0].format('Y-M-D'), values.date_range[1].format('Y-M-D')]}, ...pagination};
    axios.get('customer-balance-records', {params: query_params}).then((res) => {
      const {data} = res.data;
      pagination.total = data.total;
      this.setState({
        loading: false,
        records: data.data,
        pagination,
        total_stat: null
      });
    });
  };

  loadTotalStat = () => {
    this.setState({loading_total_stat: true});
    let values = this.formRef.getFieldsValue();
    let query_params = {
      ...values, ...{date_range: [values.date_range[0].format('Y-M-D'), values.date_range[1].format('Y-M-D')]},
      query_type: 'stat'
    };
    axios.get('customer-balance-records', {params: query_params}).then((res) => {
      const {data} = res.data;
      this.setState({
        loading_total_stat: false,
        total_stat: data
      });
    });
  };

  export = () => {
    this.setState({loading_export: true});
    let values = this.formRef.getFieldsValue();
    let query_params = {
      ...values, ...{date_range: [values.date_range[0].format('Y-M-D'), values.date_range[1].format('Y-M-D')]},
      query_type: 'export'
    };
    axios.get('customer-balance-records', {params: query_params, responseType: 'blob'}).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const file_name = `财务记录_${values.date_range[0].format('Y-M-D')}_${values.date_range[1].format('Y-M-D')}.xlsx`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
    }).finally(() => {
      this.setState({
        loading_export: false
      });
    });
  }

  pageStat = () => {
    const {records} = this.state;
    let amount = 0;
    let increment_amount = 0
    let decrement_amount = 0;
    records.forEach(item => {
      let occur_amount = parseFloat(item.amount);
      if (occur_amount > 0) {
        increment_amount += occur_amount;
      } else {
        decrement_amount += occur_amount;
      }
      amount += occur_amount;
    })
    return {increment_amount, decrement_amount, amount};
  }

  resetForm = () => {
    this.formRef && this.formRef.resetFields();
    this.loadData();
  }

  render() {
    const {loading_total_stat, records, total_stat} = this.state;
    const page_stat = this.pageStat();

    // 表格操作
    const operations = <div className='text-right'>
      <Pagination current={this.state.pagination.page} pageSize={this.state.pagination.page_size}
                  onChange={this.handlePaginationChange} showTotal={(total) => '共' + total + '条记录'}
                  total={this.state.pagination.total} pageSizeOptions={[15, 25, 50, 100]}/>
    </div>;

    return (
        <Card title='财务记录'>
          <div className='query-form-container'>
            <Form className='query-form' ref={ref => this.formRef = ref} onFinish={this.loadData}
                  initialValues={{date_range: DateRangePicker.today()}}>
              <div className='query-form__inputs_container'>
                <Form.Item label='　订单号' name='tran_no'>
                  <Input placeholder='订单号/流水号' style={{width: 160}} onPaste={() => setTimeout(this.loadData, 10)}
                         onChange={this.handleInputClear}/>
                </Form.Item>
                <Form.Item label='交易类型' name='type'>
                  <Select placeholder='选择交易类型' style={{width: 160}} onChange={this.loadData} allowClear>
                    <Option value='1'>财务加款</Option>
                    <Option value='2'>财务扣款</Option>
                    <Option value='3'>购买商品</Option>
                    <Option value='4'>订单退款</Option>
                    <Option value='5'>冻结资金</Option>
                    <Option value='6'>资金解冻</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className='query-form__inputs_container'>
                <Form.Item label='下单日期' name='date_range'>
                  <DateRangePicker onChange={this.loadData}/>
                </Form.Item>
              </div>
              <div className='query-form__operations'>
                <Space>
                  <Button type='primary' htmlType='submit'>筛选</Button>
                  <Button onClick={this.export} loading={this.state.loading_export}>导出</Button>
                  <Link onClick={this.resetForm}>重置筛选条件</Link>
                </Space>
              </div>
            </Form>
          </div>
          <Table className='mt-4' rowKey='id' dataSource={records} loading={this.state.loading}
                 pagination={false}>
            <Table.Column title='交易类型' dataIndex='type' key='type' align='center'
                          render={(value) => <CustomerBalanceRecordTypeTag value={value}/>}/>
            <Table.Column title='交易前' dataIndex='balance_before' key='balance_before' align='center'
                          render={(value) => <Money value={value}/>}/>
            <Table.Column title='金额' dataIndex='amount' key='amount' align='center'
                          render={(value) => <Money signed value={value}/>}/>
            <Table.Column title='交易后' dataIndex='balance_after' key='balance_after' align='center'
                          render={(value) => <Money value={value}/>}/>
            <Table.Column title='订单号' dataIndex='tran_no' key='tran_no' align='center'
                          render={value => value ? value : '-'}/>
            <Table.Column title='交易时间' dataIndex='created_at' key='created_at' align='center'
                          render={(value) => moment(value).format('Y-MM-DD HH:mm:ss')}/>
            <Table.Column title='备注' dataIndex='remark' key='remark' align='left'
                          render={(value) => <Text ellipsis={{tooltip: true}}
                                                   style={{maxWidth: 150}}>{value ? value : '-'}</Text>}/>
            <Table.Column title='操作' key='action' align='right' width={170} render={(value, record) =>
                <>
                  {
                    record.tran_no && record.tran_no !== '' && (record.type === '3' || record.type === '4')
                        ?
                        <Link onClick={() => this.orderDetailsModal.openModal(record.tran_no)}>查看详情</Link>
                        :
                        <Link
                            onClick={() => Modal.info({title: '该笔交易没有详情信息', ...YouyuUtils.confirmModalDefaultProps})}>查看详情</Link>
                  }
                </>
            }/>
          </Table>
          {
              records.length !== 0 && <div className='mt-4 px-4 py-2.5 flex border'>
                <div>
                  <div><Text type='secondary'>本页统计数据</Text></div>
                  <div className='mt-0.5'>
                    <Text>增加金额：<Money postfix='元' value={page_stat.increment_amount ?? 0}/> &nbsp; 减少金额：<Money
                        postfix='元' value={page_stat.decrement_amount ?? 0}/> &nbsp; 金额合计：<Money
                        postfix='元' value={page_stat.amount ?? 0}/></Text></div>
                </div>
                <div className='ml-10'>
                  <div><Text type='secondary'>全部统计数据</Text></div>
                  <div className='mt-0.5'>
                    {
                      total_stat
                          ?
                          <Text>增加金额：<Money postfix='元' value={total_stat.increment_amount ?? 0}/> &nbsp; 减少金额：<Money
                              postfix='元' value={total_stat.decrement_amount ?? 0}/> &nbsp; 金额合计：<Money
                              postfix='元' value={total_stat.amount ?? 0}/></Text>
                          :
                          <>
                            {
                              loading_total_stat
                                  ? <LoadingOutlined/>
                                  : <Link onClick={this.loadTotalStat}>点击查看</Link>
                            }
                          </>
                    }
                  </div>
                </div>
              </div>
          }
          <div className='mt-4'>{operations}</div>
          <OrderDetailsModal ref={ref => this.orderDetailsModal = ref}/>
        </Card>
    );
  }
}

export default CustomerBalanceRecords;
