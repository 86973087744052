import {Button, Form, Input, InputNumber, message, Row, Space, Radio, Spin, Typography, Checkbox} from 'antd';
import React from 'react';
import axios from '../../request/axios';

const {Text} = Typography;

class TaobaoShopSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form_loading: false,
      form_submitting: false,
      taobao_buyer_purchase_frequency_before_day: null,
      taobao_buyer_purchase_frequency_minutes: null,
      taobao_buyer_purchase_frequency_times: null,
      taobao_buyer_purchase_frequency_total_money: null,
      taobao_charge_account_limit_money_start: null,
      taobao_charge_account_limit_money_end: null,
      taobao_charge_account_limit_minutes: null,
      taobao_charge_account_limit_buyer_count: null,
      taobao_buyer_limit_money_start: null,
      taobao_buyer_limit_money_end: null,
      taobao_buyer_limit_minutes: null,
      taobao_buyer_limit_charge_account_count: null,
      taobao_ban_new_account: null,
      taobao_ban_same_account_diff_buyer: null,
      taobao_ban_new_buyer_nick: null,
    };
  }

  componentDidMount() {
    this.setState({form_loading: true});
    axios.get('customer-shop-settings').then(res => {
      const {data} = res.data;
      this.setState({
        taobao_buyer_purchase_frequency_before_day: data.taobao_buyer_purchase_frequency_before_day,
        taobao_buyer_purchase_frequency_minutes: data.taobao_buyer_purchase_frequency_minutes,
        taobao_buyer_purchase_frequency_times: data.taobao_buyer_purchase_frequency_times,
        taobao_buyer_purchase_frequency_total_money:data.taobao_buyer_purchase_frequency_total_money,
        taobao_charge_account_limit_money_start: data.taobao_charge_account_limit_money_start,
        taobao_charge_account_limit_money_end: data.taobao_charge_account_limit_money_end,
        taobao_charge_account_limit_minutes: data.taobao_charge_account_limit_minutes,
        taobao_charge_account_limit_buyer_count: data.taobao_charge_account_limit_buyer_count,
        taobao_buyer_limit_money_start: data.taobao_buyer_limit_money_start,
        taobao_buyer_limit_money_end: data.taobao_buyer_limit_money_end,
        taobao_buyer_limit_minutes: data.taobao_buyer_limit_minutes,
        taobao_buyer_limit_charge_account_count: data.taobao_buyer_limit_charge_account_count,
        taobao_ban_new_account: data.taobao_ban_new_account,
        taobao_ban_same_account_diff_buyer: data.taobao_ban_same_account_diff_buyer,
        taobao_ban_new_buyer_nick: data.taobao_ban_new_buyer_nick
      });

      data.taobao_es_product_white_list = data.taobao_es_product_white_list?.join('\n');
      data.taobao_buyer_nick_white_list = data.taobao_buyer_nick_white_list?.join('\n');
      data.taobao_buyer_nick_black_list = data.taobao_buyer_nick_black_list?.join('\n');
      data.taobao_charge_account_white_list = data.taobao_charge_account_white_list?.join('\n');
      data.taobao_charge_account_black_list = data.taobao_charge_account_black_list?.join('\n');

      this.formRef && this.formRef.setFieldsValue(data);
    }).finally(() => {
      this.setState({form_loading: false});
    });
  }

  onFinish = (values) => {
    this.setState({form_submitting: true});

    values.taobao_buyer_purchase_frequency_before_day = this.state.taobao_buyer_purchase_frequency_before_day;
    values.taobao_buyer_purchase_frequency_minutes = this.state.taobao_buyer_purchase_frequency_minutes;
    values.taobao_buyer_purchase_frequency_times = this.state.taobao_buyer_purchase_frequency_times;
    values.taobao_buyer_purchase_frequency_total_money = this.state.taobao_buyer_purchase_frequency_total_money;

    values.taobao_charge_account_limit_money_start = this.state.taobao_charge_account_limit_money_start;
    values.taobao_charge_account_limit_money_end = this.state.taobao_charge_account_limit_money_end;
    values.taobao_charge_account_limit_minutes = this.state.taobao_charge_account_limit_minutes;
    values.taobao_charge_account_limit_buyer_count = this.state.taobao_charge_account_limit_buyer_count;

    values.taobao_buyer_limit_money_start = this.state.taobao_buyer_limit_money_start;
    values.taobao_buyer_limit_money_end = this.state.taobao_buyer_limit_money_end;
    values.taobao_buyer_limit_minutes = this.state.taobao_buyer_limit_minutes;
    values.taobao_buyer_limit_charge_account_count = this.state.taobao_buyer_limit_charge_account_count;

    values.taobao_ban_new_account = this.state.taobao_ban_new_account;
    values.taobao_ban_same_account_diff_buyer = this.state.taobao_ban_same_account_diff_buyer;
    values.taobao_ban_new_buyer_nick = this.state.taobao_ban_new_buyer_nick;

    axios.post('customer-shop-settings/taobao', values).then(() => {
      message.success('保存成功');
    }).finally(() => {
      this.setState({form_submitting: false});
    });
  };

  render() {
    return (
      <Spin spinning={this.state.form_loading}>
        <Form ref={ref => this.formRef = ref} onFinish={this.onFinish} labelCol={{span: 4}} wrapperCol={{span: 20}}>

          <Form.Item
            label='交易频率'
            extra='对同一买家的购买频率限制，如不需要可不填，无订单买家时间设置为空，则限制所有买家'
          >
            <Input.Group>
              <Row gutter={8} align='middle'>
                <InputNumber min={1} style={{width: 70}} value={this.state.taobao_buyer_purchase_frequency_before_day}
                             onChange={value => this.setState({taobao_buyer_purchase_frequency_before_day: value})}/>&nbsp;&nbsp;
                <Text>天前无订单的买家，在</Text>&nbsp;&nbsp;
                <InputNumber min={1} style={{width: 70}} value={this.state.taobao_buyer_purchase_frequency_minutes}
                             onChange={value => this.setState({taobao_buyer_purchase_frequency_minutes: value})}/>&nbsp;&nbsp;
                <Text>分钟内，最多购买</Text>&nbsp;&nbsp;
                <InputNumber min={1} style={{width: 70}} value={this.state.taobao_buyer_purchase_frequency_times}
                             onChange={value => this.setState({taobao_buyer_purchase_frequency_times: value})}/>&nbsp;&nbsp;
                <Text>笔订单，最多购买合计</Text>&nbsp;&nbsp;
                <InputNumber min={1} style={{width: 100}} value={this.state.taobao_buyer_purchase_frequency_total_money}
                             onChange={value => this.setState({taobao_buyer_purchase_frequency_total_money: value})}/>&nbsp;&nbsp;
                <Text>金额订单</Text>
              </Row>
            </Input.Group>
          </Form.Item>

          <Form.Item
            label='充值账号限制'
            extra='对同一充值账号，下单买家的数量限制，如不需要可不填'
          >
            <Input.Group>
              <Row gutter={8} align='middle'>
                <Text>同一充值账号交易金额为</Text> &nbsp;&nbsp;
                <InputNumber min={1} style={{width: 70}} value={this.state.taobao_charge_account_limit_money_start}
                             onChange={value => this.setState({taobao_charge_account_limit_money_start: value})}/>-
                <InputNumber min={1} style={{width: 70}} value={this.state.taobao_charge_account_limit_money_end}
                             onChange={value => this.setState({taobao_charge_account_limit_money_end: value})}/>&nbsp;&nbsp;
                <Text>范围内，</Text>
                <InputNumber min={1} style={{width: 70}} value={this.state.taobao_charge_account_limit_minutes}
                             onChange={value => this.setState({taobao_charge_account_limit_minutes: value})}/>&nbsp;&nbsp;
                <Text>分钟内，最多支持</Text>&nbsp;&nbsp;
                <InputNumber min={1} style={{width: 70}} value={this.state.taobao_charge_account_limit_buyer_count}
                             onChange={value => this.setState({taobao_charge_account_limit_buyer_count: value})}/>&nbsp;&nbsp;
                <Text>个买家下单</Text>
              </Row>
            </Input.Group>
          </Form.Item>

          <Form.Item
              label='买家限制'
              extra='对同一买家，下单充值账号的数量限制，如不需要可不填'
          >
            <Input.Group>
              <Row gutter={8} align='middle'>
                <Text>同一买家交易金额为</Text> &nbsp;&nbsp;
                <InputNumber min={1} style={{width: 70}} value={this.state.taobao_buyer_limit_money_start}
                             onChange={value => this.setState({taobao_buyer_limit_money_start: value})}/>-
                <InputNumber min={1} style={{width: 70}} value={this.state.taobao_buyer_limit_money_end}
                             onChange={value => this.setState({taobao_buyer_limit_money_end: value})}/>&nbsp;&nbsp;
                <Text>范围内，</Text>
                <InputNumber min={1} style={{width: 70}} value={this.state.taobao_buyer_limit_minutes}
                             onChange={value => this.setState({taobao_buyer_limit_minutes: value})}/>&nbsp;&nbsp;
                <Text>分钟内，最多支持</Text>&nbsp;&nbsp;
                <InputNumber min={1} style={{width: 70}} value={this.state.taobao_buyer_limit_charge_account_count}
                             onChange={value => this.setState({taobao_buyer_limit_charge_account_count: value})}/>&nbsp;&nbsp;
                <Text>个充值账号下单</Text>
              </Row>
            </Input.Group>
          </Form.Item>

          <Form.Item
              label='风控拦截'
              extra='被风控拦截后，订单自动失败，此时可人工审核，通过“重新提交”功能为该用户充值'
          >
            <Checkbox checked={this.state.taobao_ban_new_account} onChange={e => this.setState({taobao_ban_new_account: e.target.checked})}>新充值账号首次下单</Checkbox>
            <Checkbox checked={this.state.taobao_ban_same_account_diff_buyer} onChange={e => this.setState({taobao_ban_same_account_diff_buyer: e.target.checked})}>不同淘宝买家用相同充值账号下单</Checkbox>
            <Checkbox checked={this.state.taobao_ban_new_buyer_nick} onChange={e => this.setState({taobao_ban_new_buyer_nick: e.target.checked})}>新买家首次下单</Checkbox>
          </Form.Item>

          <Form.Item
            name='taobao_maximum_amount_per_order'
            label='订单最大交易金额'
            extra='单笔订单的最大交易金额，当大于所设置金额时，该笔订单将被忽略不予处理'
            rules={[
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!value || (!isNaN(value) && parseFloat(value) >= 0.0001 && parseFloat(value) < 90000000)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('请输入正确的金额'));
                }
              })
            ]}
          >
            <Input style={{width: 130, textAlign: 'right'}} addonBefore='￥' suffix='元'/>
          </Form.Item>

          <Form.Item
            name='taobao_buyer_nick_white_list'
            label='买家昵称白名单'
            extra='一行一个买家昵称，处于白名单的买家，不受单笔订单最大交易金额和购买频率限制'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>

          <Form.Item
              name='taobao_es_product_white_list'
              label='平台商品ID白名单'
              extra='一行一个平台商品ID，处于白名单的平台商品ID，不受单笔订单最大交易金额和购买频率限制'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>

          <Form.Item
            name='taobao_buyer_nick_black_list'
            label='买家昵称黑名单'
            extra='一行一个买家昵称，处于黑名单的买家，忽略订单不予处理'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>

          <Form.Item
            name='taobao_charge_account_black_list'
            label='充值账号黑名单'
            extra='一行一个充值账号，处于黑名单的充值账号，忽略订单不予处理'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>

          <Form.Item
            name='taobao_charge_account_white_list'
            label='充值账号白名单'
            extra='一行一个充值账号，不为空时，只有白名单账号才会充值，为空时，不限制账号充值'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>

          <Form.Item
            name='taobao_send_good_delay_seconds'
            label='订单发货延迟'
            extra='淘宝订单发货延迟，淘宝订单发货太快，可能会被淘宝处罚，可设置延迟时间'
            rules={[
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!value || (!isNaN(value) && parseFloat(value) >= 1 && parseFloat(value) < 90000000)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('请输入正确的时间'));
                }
              })
            ]}
          >
            <Input style={{width: 130, textAlign: 'right'}} suffix='秒'/>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 4,
              span: 10
            }}
          >
            <Space>
              <Button type='primary' htmlType='submit' loading={this.state.form_submitting}>
                保存
              </Button>
              <Button onClick={this.handleReturn}>
                返回
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    );
  }
}

export default TaobaoShopSettings;